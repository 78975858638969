
import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
import { RouterMixin } from "@/mixins";
import {
  LineChart,
  FixedYaxisChart,
  ProjectsInfoTable,
  ProjectsInfoTableHeader,
} from "@/components";
import {
  IChartTimes,
  IChartTrackedTime,
  IInterval,
  IProject,
  ITeam,
  ITimeUser,
  ITimesProjectUser,
} from "@/types/api";
import dayjs from "dayjs";
import { MonthYearEntry, DataChart } from "./types";
import { getFormattedTime } from "@/utils/dateHelper";
import { hexToRGB } from "@/utils/colorsHelper";
import { getSortedProjectsInfo } from "@/utils/sortProjectsInfo";

@Component({
  components: {
    LineChart,
    ProjectsInfoTable,
    ProjectsInfoTableHeader,
    FixedYaxisChart,
  },
})
export default class ProjectsInfo extends Mixins(RouterMixin) {
  public selectProject: boolean = false;
  public timesChart: IChartTimes = {};
  public selectedProjects: IProject[] = [];
  public allProjects: IProject[] = [];
  private countChangeRoutes: number = 0;
  private chartColor: { background: string; border: string }[] = [
    { background: "rgba(254, 204, 145, 0.30)", border: "#FECC91" },
    { background: "rgba(232, 140, 217, 0.20)", border: "#E88CD9" },
    { background: "rgba(163, 109, 203, 0.20)", border: "#A36DCB" },
    { background: "rgba(229, 200, 95, 0.30)", border: "#E5C85F" },
    { background: "rgba(172, 210, 195, 0.50)", border: "#ACD2C3" },
    { background: "rgba(58, 119, 224, 0.20)", border: "#3A77E0" },
  ];

  @Ref("projectsHeader")
  projectsHeader!: HTMLDivElement;

  @Ref("tableFixedRef")
  tableFixedRef!: HTMLDivElement;

  @Ref("chartScroll")
  chartScroll!: HTMLDivElement;

  @Ref("tableScroll")
  tableScroll!: HTMLDivElement;

  handleScrollChart() {
    this.tableScroll.scrollLeft = this.chartScroll.scrollLeft;
    const tableFixedRef = document.querySelector(
      ".table-content-fixed-header"
    )!;
    tableFixedRef.scrollLeft = this.chartScroll.scrollLeft;
  }

  handleScrollTable(event) {
    this.chartScroll.scrollLeft = this.tableScroll.scrollLeft;
    const tableFixedRef = document.querySelector(
      ".table-content-fixed-header"
    )!;
    tableFixedRef.scrollLeft = this.tableScroll.scrollLeft;
  }

  async created(): Promise<void> {
    await this.fetchAllProjects();
    const { projects } = this.routerParams;
    if (!projects || !projects.length) return;

    this.selectedProjects = this.allProjects.filter((project) =>
      projects.includes(project.name)
    );
    this.fetchTimesChart();
  }

  get chartGroupedByProjects() {
    if (!this.timesChart.timesProjectUsers) return [];

    let projects: ITimesProjectUser[] = [];
    let timesProjectUsers = this.timesChart.timesProjectUsers;

    let projectIds = timesProjectUsers
      .map((item) => item.projectId)
      .filter(this.onlyUnique);
    projectIds.forEach((id) => {
      let project = timesProjectUsers.filter((item) => item.projectId == id);
      if (project && project.length) projects.push(project[0]);
    });
    const queryProjects = this.$route.query.projects;
    const query = Array.isArray(queryProjects)
      ? queryProjects.join("%20")
      : (this.$route.query.projects as string);

    return getSortedProjectsInfo(projects, query)
      .map((project) => ({
        ...project,
        periodStartDate: dayjs(project.periodStartDate).format("DD.MM.YYYY"),
        periodEndDate: dayjs(project.periodEndDate).format("DD.MM.YYYY"),
      }))
      .filter(({ projectName }) =>
        (this.routerParams.projects as string[]).includes(projectName)
      );
  }

  get totalPeriod() {
    const totalPeriod = {
      startPeriod: this.chartGroupedByProjects
        .map(({ periodStartDate }) => {
          const parts = periodStartDate.split(".");
          const formattedDate = new Date(
            parts[2] as any,
            (parts[1] as any) - 1,
            parts[0] as any
          );
          formattedDate.setHours(0, 0, 0, 0);
          return dayjs(formattedDate);
        })
        .sort((a, b) => (a as any) - (b as any)),
      endPeriod: this.chartGroupedByProjects
        .map(({ periodEndDate }) => {
          const parts = periodEndDate.split(".");
          const formattedDate = new Date(
            parts[2] as any,
            (parts[1] as any) - 1,
            parts[0] as any
          );
          formattedDate.setHours(0, 0, 0, 0);
          return dayjs(formattedDate);
        })
        .sort((a, b) => (b as any) - (a as any)),
    };
    return {
      startPeriod: dayjs(totalPeriod.startPeriod[0]).format("DD.MM.YYYY"),
      endPeriod: dayjs(totalPeriod.endPeriod[0]).format("DD.MM.YYYY"),
    };
  }

  get membersTimes() {
    if (!this.timesChart.timesProjectUsers) return [];

    let allUsers: { user: ITimeUser; trackedTimes: IChartTrackedTime[] }[] = [];
    let timesProjectUsers = this.timesChart.timesProjectUsers;
    let memberIds = timesProjectUsers
      .sort((a, b) => a.userTracked.name.localeCompare(b.userTracked.name))
      .map((item) => item.userTracked.id)
      .filter(this.onlyUnique);
    memberIds.forEach((id) => {
      let user = timesProjectUsers.filter((item) => item.userTracked.id == id);
      if (user && user.length) {
        let userFullTracked = user.reduce((userTrackedTimes, times) => {
          userTrackedTimes.push(...times.trackedTimes);
          return userTrackedTimes;
        }, [] as IChartTrackedTime[]);
        allUsers.push({
          user: user[0].userTracked,
          trackedTimes: userFullTracked,
        });
      }
    });

    const groupedUsers = allUsers.reduce((users, item) => {
      const existingPosition = users.find(
        (element) => element.position === item.user.position
      );
      if (existingPosition) {
        existingPosition.users.push(item);
      } else {
        users.push({
          position: item.user.position,
          users: [item],
        });
      }
      return users;
    }, [] as { position: string; users: { user: ITimeUser; trackedTimes: IChartTrackedTime[] }[] }[]);

    return groupedUsers.sort((a, b) => a.position.localeCompare(b.position));
  }

  get timesMonth() {
    if (!this.timesChart.intervals) return [];

    const lastMonths = this.timesChart.intervals;

    const months: { year: number; months: IInterval[] }[] = [];
    const lastStartDate = new Date(this.timesChart.intervals[this.timesChart.intervals.length - 1].startDate);

    for (let i = 0; i < lastMonths.length; i++) {
      const lastMonth = new Date(lastStartDate);
      lastMonth.setDate(1);
      lastMonth.setMonth(lastStartDate.getMonth() - i);

      const year = lastMonth.getFullYear();
      const month = lastMonth.getMonth() + 1;

      const filteredDates = lastMonths.filter(({ startDate }) => {
        const start = new Date(startDate);
        return start.getFullYear() === year && start.getMonth() + 1 === month;
      });

      if (filteredDates.length > 0) {
        const yearEntry = months.find((entry) => entry.year === year);
        if (yearEntry) {
          yearEntry.months.push(...filteredDates);
        } else {
          months.push({ year, months: filteredDates });
        }
      } else {
        // Если за текущий месяц нет данных, добавьте пустой элемент
        const yearEntry = months.find((entry) => entry.year === year);
        if (yearEntry) {
          yearEntry.months.push([] as any);
        } else {
          months.push({ year, months: [] });
        }
      }
    }

    const columns = [
      ...months
        .filter(({ months }) => !!months.length)
        .map((it) => ({
          ...it,
          months: [
            ...it.months.map((date) => {
              const dateMonth = dayjs(date.startDate).get("M") + 1;

              return { ...date, isStartOfTheYear: dateMonth === 1 };
            }),
          ].reverse(),
        })),
    ].reverse();

    return columns;
  }

  get chartLabels() {
    const labels = this.timesMonth
      .map(({ months, year }) => {
        const labelsMonth = months.map((month, idx) => {
          const monthIndex = dayjs(month.startDate).get("month") + 1;
          if (idx === 0) return `${monthIndex} (${year})`;
          return monthIndex.toString();
        });
        return labelsMonth;
      })
      .flat();
    return labels;
  }

  get chartDataNew() {
    let indexColor = 0;
    const columnData = this.membersTimes.reduce((all: DataChart[], item) => {
      const columnUsers = item.users.map(({ user, trackedTimes }) => {
        const tracker = this.timesMonth
          .map(({ year, months }) => {
            const trackerYear = months.map((month) => {
              const currentMonth = trackedTimes.filter(
                (time) => time.monthDate === month.startDate && time.duration
              );
              if (currentMonth && currentMonth.length > 0) {
                var totlaMillesecondsTrackedTimes = currentMonth.reduce(
                  (acc, curr) => (acc += curr.duration.totalMilliseconds),
                  0
                );
                const totalHoursTrackedTimes = Math.round(
                  totlaMillesecondsTrackedTimes / 1000 / 60 / 60
                );
                return {
                  ...month,
                  totalHoursTrackedTimes,
                };
              }
              return {
                ...month,
                totalHoursTrackedTimes: 0,
              };
            });

            return {
              year,
              trackerYear,
            };
          })
          .map(({ trackerYear }) => trackerYear)
          .flat();

        const totalTrackedTimeByMonths = tracker
          .filter((it) => !!it)
          .map((item) => item!.totalHoursTrackedTimes);

        const repeatedCount = Math.floor(indexColor / this.chartColor.length);
        let chartColor =
          this.chartColor[indexColor - this.chartColor.length * repeatedCount];
        indexColor++;

        const borderColor = "#000000".replace(/0/g, function () {
          return (~~(Math.random() * 16)).toString(16);
        });
        const backgroundColor = hexToRGB(borderColor, 0.05);

        return {
          label: user.name,
          backgroundColor,
          borderWidth: 0.5,
          borderColor,
          fill: true,
          data: totalTrackedTimeByMonths,
        } as DataChart;
      });

      all = [...all, ...columnUsers];
      return all;
    }, []);

    return columnData;
  }

  get devopsNames() {
    return this.chartGroupedByProjects
      .filter(({ devops }) => !!devops)
      .map((item) => item.devops.name);
  }

  get managersNames() {
    return this.chartGroupedByProjects
      .filter(({ manager }) => !!manager)
      .map((item) => item.manager.name);
  }

  get tachStacks() {
    return this.chartGroupedByProjects
      .map((item) => (item.techStack || "").split("\n"))
      .flat()
      .join(",")
      .split(",");
  }

  get mambersTrackedTime() {
    if (!this.timesChart.timesProjectUsers) return [];

    let users: ITimesProjectUser[] = [];
    let timesProjectUsers = this.timesChart.timesProjectUsers;
    let memberIds = timesProjectUsers
      .sort((a, b) => a.userTracked.name.localeCompare(b.userTracked.name))
      .map((item) => item.userTracked.id)
      .filter(this.onlyUnique);
    memberIds.forEach((id) => {
      let user = timesProjectUsers.filter((item) => item.userTracked.id == id);
      if (user && user.length) users.push(user[0]);
    });

    const groupedUsers = users.reduce((users, user) => {
      const { name, position } = user.userTracked;

      if (!users[position]) {
        users[position] = {};
      }

      if (!users[position][name]) {
        users[position][name] = [];
      }

      users[position][name] = user.trackedTimes;

      return users;
    }, {} as Record<string, Record<string, IChartTrackedTime[]>>);

    return groupedUsers;
  }

  @Watch("$route")
  async changeRoute(): Promise<void> {
    const { projects } = this.routerParams;
    this.selectedProjects = projects
      ? this.allProjects.filter((p) => projects.some((q) => q === p.name))
      : [];

    await this.fetchTimesChart();

    this.countChangeRoutes += 1;
  }

  addProject(value) {
    this.selectedProjects.push(
      ...this.allProjects.filter((pr) => pr.id === value.id)
    );
    var projects = this.selectedProjects;
    this.changeProjects(projects);
  }

  removeProject(id: number) {
    const projects = this.chartGroupedByProjects
      .filter((item) => item.projectId !== id)
      .map(({ projectName }) => ({ name: projectName }));
    this.changeProjects(projects);
  }

  changeProjects(value): void {
    if (this.selectedProjects.some((s) => s.name === value.name)) return;

    this.selectedProjects = [...value];

    const path = `/projects-info`;

    this.onCloseSelectProject();

    if (this.selectedProjects.length) {
      this.$router.push({
        path,
        query: {
          projects: encodeURIComponent(value.map((s) => s.name).join()),
        },
      });
    } else {
      this.$router.push({ path });
    }
  }

  async fetchTimesChart() {
    let projectIds = this.selectedProjects.map((project) => project.id);

    if (projectIds.length == 0) {
      this.timesChart = {};
      return;
    }

    this.timesChart = await this.$store.dispatch("GetTimesChart", {
      projectIds,
      undefined,
      removeLoaderFlag: false,
    });
  }

  async fetchAllProjects() {
    const projects = await this.$store.dispatch("GetAllProjects");
    this.allProjects = projects;
  }

  public onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  onBack() {
    this.$router.go(-1 - this.countChangeRoutes);
  }

  onCloseSelectProject() {
    setTimeout(() => {
      this.selectProject = false;
    }, 125);
  }

  onStartSelectProjetc(event) {
    this.selectProject = true;
  }

  mounted() {
    document.body.classList.add("app-projects-info-body");
  }

  unmounted() {
    document.body.className = "removed";
  }
}
