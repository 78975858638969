import { Component, Vue } from "vue-property-decorator";
import { IRoute } from "@/types/router";

@Component
class RouterMixin extends Vue {
  get routerParams(): IRoute {
    const { params, query } = this.$route;

    const paramsDefined = Object.keys(params).length;
    const queryDefined = Object.keys(query).length;

    const { month, year } = params;

    const queryProjects =
      queryDefined && query.projects
        ? decodeURIComponent("" + query.projects).split(",")
        : null;

    const queryPlannedProjects =
      queryDefined && query.plannedProjects
        ? decodeURIComponent("" + query.plannedProjects).split(",")
        : null;

    const savedViewMb = localStorage.getItem('view') || process.env.DEFAULT_VIEW || "quarter"
    let queryView = queryDefined && query.view ? query.view : savedViewMb;

    let view: "quarter" | "month" = 'month';

    switch (queryView) {
      case "month":
      case "quarter":
        view = queryView;
        break;
      default:
        queryView = "month";
    }

    return {
      month: paramsDefined ? Number(month) : new Date().getMonth() + 1,
      year: paramsDefined ? Number(year) : new Date().getFullYear(),
      projects: queryProjects,
      plannedProjects: queryPlannedProjects,
      view,
    };
  }
}

export default RouterMixin;
