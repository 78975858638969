export const getSortedProjectsInfo = (projects, route: string) => {
  const query = decodeURIComponent(route.replace(/%2520/g, " "))
    .split(",")
    .map((q) => decodeURIComponent(q));
  const indexMap = new Map();
  query.forEach((value, index) => {
    indexMap.set(value, index);
  });
  return [...projects].sort((a, b) => {
    const nameA = a.projectName;
    const nameB = b.projectName;
    const indexA = indexMap.get(nameA);
    const indexB = indexMap.get(nameB);
    return indexA - indexB;
  });
};
